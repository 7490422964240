import React from 'react'
import { createRoot } from 'react-dom/client'
import { SocialMediaBar } from '@ampcore/design-system'
import jsCookie from 'js-cookie'
import Analytics from '../SiteNav/analytics.js'

export default class SocialMediaBarElement extends HTMLElement {
  connectedCallback() {
    this.mountPoint = document.createElement('div')

    const findAnAdvisorBtnText = this.getAttribute(
      'data-find-an-advisor-btn-text'
    )
    const findAnAdvisorBtnUrl = this.getAttribute(
      'data-find-an-advisor-btn-url'
    )

    const shadowRoot = this.attachShadow({ mode: 'open' })
    shadowRoot.appendChild(this.mountPoint)

    this.template = this.querySelector('#social-media-bar-template')
    if (this.template && this.template.content) {
      shadowRoot.appendChild(this.template.content)
    }

    const root = createRoot(this.mountPoint)

    // TODO: determine whether logic can be removed
    // force other domains to always display Find an Advisor to logged in and expired clients
    const host = window.location.hostname
    const isAmeripriseDotCom =
      /localhost/.test(host) || /\.ameriprise\.com/.test(host)
    const userStatus = jsCookie.get('lastAmpAuthState') ?? 'unrecognized'
    let isFindAnAdvisorShown
    if (isAmeripriseDotCom) {
      isFindAnAdvisorShown = userStatus !== 'active' && userStatus !== 'expired'
    } else {
      isFindAnAdvisorShown = true
    }

    function formatQueryStringParameter(uri, key, value) {
      if (uri) {
        const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
        const separator =
          uri.indexOf('?') && uri.indexOf('?') !== -1 ? '&' : '?'
        if (uri.match(re)) {
          return uri.replace(re, '$1' + key + '=' + value + '$2')
        }
        return uri + separator + key + '=' + value
      }
    }
    const findAnAdvisorLink = formatQueryStringParameter(
      findAnAdvisorBtnUrl,
      'lastAmpAuthState',
      userStatus
    )

    function handleClick(event) {
      // Add Analytics
      const disclaimerMessage =
        event.currentTarget && event.currentTarget.innerText
      try {
        if (disclaimerMessage === 'Find an Advisor') {
          const customAnalytics = new Analytics()
          customAnalytics.setSatelliteTrack('initiativeFindAnAdvisor')
        }
        const disclaimerAnalytics = new Analytics()
        disclaimerAnalytics.ping(disclaimerMessage, 'footer')
      } catch (e) {
        console.log(e)
      }
    }

    // hide DOM during page load
    root.render(
      <div className="u-adel-reset">
        <div className="u-block" style={{ display: 'none' }}>
          <SocialMediaBar
            content={{
              isFindAnAdvisorShown,
              findAnAdvisorBtnText,
              findAnAdvisorLink,
              handleClick
            }}
          />
        </div>
      </div>
    )
  }
}
