import { useState, useEffect } from 'react'

export const useSetBodyScroll = (lock: boolean) => {
  const [isLocked, setIsLocked] = useState(lock)

  useEffect(() => {
    const html = document.querySelector('html')
    if (html) {
      html.style.overflow = isLocked ? 'hidden' : 'auto'
    }
  }, [setIsLocked, isLocked])

  return setIsLocked
}
