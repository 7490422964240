import React from 'react'
import xss from 'xss'
import { BrandIconId } from '../BrandIcon/BrandIconId.type'
import BrandIcon from '../BrandIcon'
import { BrandIconSize } from '../BrandIcon/BrandIconSize.type'
import { Link } from '../../types/Link.type'

export type MostVisitedLinksMenuItems = Link & {
  icon: BrandIconId
}

export type MostVisitedLinksProps = {
  menuItems: MostVisitedLinksMenuItems[]
  heading: string
  isEnabled?: boolean
  testId?: string
}

export const MostVisitedLinks = (props: MostVisitedLinksProps) => {
  const { heading, menuItems, isEnabled, testId } = props

  if (!isEnabled) {
    return null
  }

  return (
    <div className="MostVisitedLinks" data-testid={testId ? `${testId}` : null}>
      <div className="u-sm-hidden">
        <h4
          className="u-textBold u-textCenter u-marginBottom6gu"
          data-testid={testId ? `${testId}-heading-desktop` : null}
          dangerouslySetInnerHTML={{ __html: xss(heading) }}
        />
        <div className="u-flex u-flex-row u-flexJustifyCenter u-marginHoriz3gu">
          {menuItems.map((menuItems, index) => (
            <a
              key={index}
              className="u-textCenter u-marginRight7gu"
              href={xss(menuItems.url)}
              aria-label={`Read more about ${menuItems.name}`}
              data-analytics="search-most-visited-links"
              data-testid={testId ? `${testId}-link-desktop-${index}` : null}
            >
              <div className="u-marginBottom1gu">
                <BrandIcon
                  icon={menuItems.icon}
                  size={BrandIconSize.SMALL}
                  style={{ fill: '#003f6b' }}
                  testId={`icon-${testId}-${index}`}
                />
              </div>
              <span
                className="u-flexWrap"
                dangerouslySetInnerHTML={{ __html: xss(menuItems.name) }}
              />
            </a>
          ))}
        </div>
      </div>
      <div className="u-md-hidden u-lg-hidden">
        <h4
          className="u-textBold u-marginBottom6gu"
          data-testid={testId ? `${testId}-heading-desktop` : null}
          dangerouslySetInnerHTML={{ __html: xss(heading) }}
        />
        {menuItems.map((menuItems, index) => (
          <div key={index} className="u-marginTop4gu u-flex u-flex-row ">
            <a
              href={xss(menuItems.url)}
              className="u-textSizeMsr"
              aria-label={`Read more about ${menuItems.name}`}
              data-analytics="search-most-visited-links"
              data-testid={testId ? `${testId}-link-mobile-${index}` : null}
              dangerouslySetInnerHTML={{ __html: xss(menuItems.name) }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
