import React, { useState, useEffect, useRef } from 'react'
import './style.module.scss'
import { BrandIcon } from '../BrandIcon/BrandIcon'
import { BrandIconId } from '../BrandIcon/BrandIconId.type'
import { MenuButton } from '../MenuButton/MenuButton'
import { useSetBodyScroll } from './useSetBodyScroll'
import { Analytics } from '../../lib/Analytics/Analytics'
import { AppMenu, AppMenuProps } from '../AppMenu/AppMenu'
import { SearchPanelProps } from '../SearchPanel/SearchPanel'
import { AppMenuMobile } from '../AppMenuMobile/AppMenuMobile'
import { MenuItem } from '../../types/MenuItem.type'
import { Link } from '../../types/Link.type'

// TODO:
// personalized prospect
// logged in menu
// fetch for dynamic menu with cache
// data analytics
// cms impersonation

export type AppBarProps = {
  logoHref: string
  mobileBottomMenuItems: MenuItem[]
  primaryButton?: Link
  secondaryButton?: Link
  showHeaderLinks?: boolean
  showMenuButton?: boolean
  stickyHeader?: boolean
  analyticsName?: string
  menu?: AppMenuProps
  searchPanel?: SearchPanelProps
  testId?: string
}

const WINDOW_SIZE_TABLET = 767

export const AppBar = (props: AppBarProps) => {
  const {
    mobileBottomMenuItems,
    logoHref,
    primaryButton,
    secondaryButton,
    showHeaderLinks = true,
    showMenuButton = true,
    stickyHeader = false,
    menu,
    searchPanel,
    analyticsName,
    testId
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const appBarRef = useRef<HTMLDivElement>(null)
  const menuButtonRef = useRef<HTMLButtonElement>(null)
  const mobileNavSectionRef = useRef<HTMLDivElement>(null)
  const analytics = new Analytics('AppBar', analyticsName)

  const [dimensions, setDimensions] = useState({
    width: appBarRef.current?.clientWidth,
    height: appBarRef.current?.clientWidth
  })

  const setBodyScrollLock = useSetBodyScroll(false)

  const closeMenu = () => {
    setIsOpen(false)
    setBodyScrollLock(false)
  }

  const openMenu = () => {
    setIsOpen(true)
    setBodyScrollLock(true)
  }

  const handleMenuButtonClick = () => {
    if (isOpen) {
      closeMenu()
      return
    }
    openMenu()
  }

  const onResize = () => {
    setDimensions({
      width: appBarRef.current?.clientWidth,
      height: appBarRef.current?.clientHeight
    })

    if (dimensions.width && dimensions.width >= WINDOW_SIZE_TABLET) {
      closeMenu()
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeMenu()
      return
    }
    if (event.key === 'Tab') {
      // trap focus
      if (!isOpen || !mobileNavSectionRef.current) return
      const items =
        mobileNavSectionRef.current.querySelectorAll<HTMLElement>('button, a')
      const lastFocusable = items[items.length - 1]
      if (event.shiftKey) {
        if (document.activeElement === menuButtonRef.current) {
          event.preventDefault()
          lastFocusable.focus()
        }
      } else if (document.activeElement === lastFocusable) {
        event.preventDefault()
        menuButtonRef.current?.focus()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onResize, handleKeyDown])

  return (
    <div
      className={`AppBar${stickyHeader ? ' u-height' : ''}`}
      {...analytics.dataTargetAnalyticAttribute()}
      ref={appBarRef}
    >
      <div className={`${stickyHeader && 'AppBar--sticky'}`}>
        <div className="AppBar-topSection u-bgColorWhite">
          <div className="u-sizeConstrained">
            <div className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween u-sm-paddingRight0">
              {showMenuButton && (
                <MenuButton
                  className="u-lg-hidden"
                  open={isOpen}
                  onClick={handleMenuButtonClick}
                  ref={menuButtonRef}
                  testId={testId ? `${testId}-menu-button` : undefined}
                />
              )}
              <div className="u-flex u-sm-flexAlignSelfCenter">
                <a
                  href={logoHref}
                  tabIndex={-1}
                  {...analytics.dataAnalyticAttribute('AmpLog')}
                  data-testid={testId ? `${testId}-logo` : null}
                >
                  <BrandIcon
                    className="u-sm-hidden u-md-hidden"
                    icon={BrandIconId.AMPF_LOGO}
                    style={{ width: '150px', height: '52px', fill: '#003f6b' }}
                    ariaLabel="Ameriprise Financial"
                    testId={testId ? `${testId}-mobile` : undefined}
                  />
                  <BrandIcon
                    className="u-lg-hidden"
                    icon={BrandIconId.AMPF_LOGO}
                    style={{ width: '125px', height: '44px', fill: '#003f6b' }}
                    ariaLabel="Ameriprise Financial"
                    testId={testId ? `${testId}-large` : undefined}
                  />
                </a>
              </div>
              <div className="AppHeader-topRightSection">
                <div className="u-md-flexRow u-lg-flexRow">
                  <ul className="u-flex u-flexAlignItemsCenter u-flexJustifyCenter">
                    {showHeaderLinks && (
                      <>
                        <li className="u-hidden u-lg-block u-borderRight u-paddingHoriz2gu">
                          <a
                            className="Link u-marginLeft1gu u-textPrimary u-linkClean u-flex u-flexJustifyCenter u-flexAlignItemsCenter"
                            href="/privacy-security-fraud/online-security-guarantee"
                            data-qa="OnlineSecurityURL"
                            {...analytics.dataAnalyticAttribute(
                              'OnlineSecurityGuarantee'
                            )}
                          >
                            <span
                              className="adel-material-symbols-16 u-textColorActionBlue u-paddingRight4gu"
                              aria-hidden="true"
                            >
                              security
                            </span>{' '}
                            Online security guarantee
                          </a>
                        </li>
                        <li className="u-hidden u-lg-block u-paddingHoriz2gu">
                          <a
                            className="Link u-textPrimary u-linkClean"
                            data-qa="CustomerServiceUrl"
                            href="/customer-service"
                            {...analytics.dataAnalyticAttribute(
                              'CustomerService'
                            )}
                          >
                            Customer Service
                          </a>
                        </li>
                      </>
                    )}
                    {primaryButton?.name && primaryButton?.url && (
                      <li className="u-paddingLeft2gu">
                        <a
                          href={primaryButton?.url}
                          target={primaryButton?.target}
                          className="Button Button--primary Button--small"
                          data-testid={
                            testId ? `${testId}-primary-button` : null
                          }
                          {...analytics.dataAnalyticAttribute(
                            primaryButton?.analyticsName &&
                              primaryButton.analyticsName !== ''
                              ? primaryButton.analyticsName
                              : 'PrimaryButton'
                          )}
                        >
                          {primaryButton?.name}
                        </a>
                      </li>
                    )}
                    {secondaryButton?.name && secondaryButton?.url && (
                      <li className="u-paddingLeft2gu">
                        <a
                          href={secondaryButton?.url}
                          target={secondaryButton?.target}
                          className="Button Button--secondary--action-blue Button--small"
                          data-testid={
                            testId ? `${testId}-secondary-button` : null
                          }
                          {...analytics.dataAnalyticAttribute(
                            secondaryButton?.analyticsName &&
                              secondaryButton.analyticsName !== ''
                              ? secondaryButton.analyticsName
                              : 'SecondaryButton'
                          )}
                        >
                          {secondaryButton?.name}
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {menu && (
          <AppMenu
            className="u-hidden u-lg-block"
            {...menu}
            {...searchPanel}
            primaryButton={primaryButton}
            secondaryButton={secondaryButton}
          />
        )}
        <AppMenuMobile
          className="u-block u-lg-hidden"
          {...searchPanel}
          menuItems={menu?.menuItems?.filter(
            menuItem => menuItem.name !== 'Home'
          )}
          mobileBottomMenuItems={mobileBottomMenuItems}
          isOpen={isOpen}
          testId={testId}
          mobileNavSectionRef={mobileNavSectionRef}
        />
      </div>
    </div>
  )
}
