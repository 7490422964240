import React from 'react'

// contains icons from ADEL icon-sprite.svg used in the social media bar
export const SocialMediaBarIcons = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="u-hidden">
    <symbol id="ampf-compass" viewBox="0 0 100 100">
      <path d="M83.5 93.1c0-3.7 3.2-6.8 7.1-6.8s7 3.1 7 6.8-3.1 6.9-7 6.9-7.1-3.1-7.1-6.9Zm1.4 0c0 3.1 2.5 5.5 5.7 5.5s5.7-2.4 5.7-5.5-2.5-5.5-5.7-5.5-5.7 2.4-5.7 5.5Zm9.2 3.9h-1.3l-2.3-3.5h-1.4V97h-1.2v-7.7h3.4c.7 0 1.3 0 1.9.4.6.3.9 1 .9 1.6 0 1.5-1.1 2.1-2.5 2.1l2.4 3.5Zm-3.7-4.5c1.1 0 2.6.2 2.6-1.2s-.9-1.1-1.9-1.1h-2v2.3h1.3Z" />
      <path d="M49 0C23.3 0 2.4 20.9 2.4 46.6S23.3 93.2 49 93.2s46.6-20.9 46.6-46.6S74.7 0 49 0Zm28.2 74.7-19.9-8.2L49 86.4l-8.3-19.9-19.9 8.2 8.3-19.9-19.9-8.3 19.9-8.3-8.3-19.9 19.9 8.2L49 6.6l8.3 19.9 20-8.2-8.2 19.9L89 46.5l-19.9 8.3 8.2 19.9Z" />
      <path d="m49.1 46.4 6.6 16.1 16-38.8-38.8 16.1 16.2 6.6z" />
    </symbol>
    <symbol id="social-facebook-white" viewBox="0 0 100 100">
      <title>Facebook</title>
      <path d="M97.8,50c-.02-26.4-21.43-47.79-47.83-47.77C23.57,2.24,2.18,23.66,2.2,50.06c.01,21.92,14.93,41.02,36.2,46.34v-31.8h-9.8v-14.6h9.8v-6.3c0-16.3,7.4-23.8,23.4-23.8,3,0,8.2.6,10.4,1.2v13.1l-5.6-.2c-7.8,0-10.8,3-10.8,10.7v5.2h15.6l-2.7,14.6h-13v32.8c23.7-2.8,42-23,42-47.4l.1.1Z" />
    </symbol>
    <symbol id="social-instagram" viewBox="0 0 100 100">
      <title>Instagram</title>
      <path d="M29.3.3c-5.3.3-9 1.2-12.1 2.4-3.3 1.3-6.1 3-8.9 5.8a24.5 24.5 0 0 0-5.7 8.8A34.8 34.8 0 0 0 .3 29.5C0 34.8 0 36.5 0 50s0 15.3.3 20.6c.3 5.3 1.1 9 2.4 12.1a24.9 24.9 0 0 0 14.6 14.6c3.2 1.3 6.9 2.1 12.2 2.3 5.3.3 7 .3 20.6.3 13.6 0 15.3 0 20.6-.3s9-1.2 12.1-2.4a24.9 24.9 0 0 0 14.6-14.6c1.3-3.2 2.1-6.9 2.3-12.2.3-5.3.3-7 .3-20.6s-.1-15.3-.3-20.6-1.1-9-2.4-12.1A24.9 24.9 0 0 0 82.7 2.6C79.5 1.3 75.8.5 70.5.3S63.5 0 50 0 34.6 0 29.3.3m.6 90.4a27 27 0 0 1-9.3-1.7 16 16 0 0 1-9.5-9.4 29 29 0 0 1-1.8-9.3C9.1 65 9 63.4 9 50s0-15 .3-20.2a27 27 0 0 1 1.7-9.3c.9-2.3 2-4 3.7-5.8s3.4-2.8 5.7-3.7a27 27 0 0 1 9.3-1.7C35 9 36.6 9 50 9s15 0 20.2.3c4.9.2 7.5 1 9.3 1.7 2.3.9 4 2 5.8 3.7s2.8 3.4 3.7 5.8c.7 1.7 1.5 4.4 1.8 9.2.2 5.3.3 6.9.3 20.2 0 13.4 0 15-.3 20.2-.2 5-1 7.6-1.7 9.3a16 16 0 0 1-9.4 9.5c-1.8.7-4.4 1.6-9.3 1.8-5.3.2-6.9.3-20.2.3-13.4 0-15 0-20.2-.2m40.7-67.5a6 6 0 1 0 6-6 6 6 0 0 0-6 6M24.3 50a25.7 25.7 0 1 0 51.4 0 25.7 25.7 0 0 0-51.4 0m9 0a16.7 16.7 0 1 1 33.4 0 16.7 16.7 0 0 1-33.4 0" />
    </symbol>
    <symbol
      id="social-linkedin-white"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <title>LinkedIn</title>
      <path
        fill="#fff"
        d="M92.62 0H7.38A7.37 7.37 0 0 0 0 7.38v85.24C0 96.7 3.3 100 7.38 100h85.24c4.08 0 7.38-3.3 7.38-7.38V7.38C100 3.3 96.7 0 92.62 0ZM29.81 85.19H14.78V37.43h15.03v47.76Zm-7.52-54.37c-4.79 0-8.67-3.64-8.67-8.63s3.88-8.63 8.67-8.63 8.67 3.65 8.67 8.63-3.88 8.63-8.67 8.63Zm62.94 54.41H70.2V59.14c0-7.69-3.27-10.07-7.49-10.07-4.46 0-8.83 3.36-8.83 10.26v25.89H38.85V37.46h14.46v6.62h.19c1.45-2.94 6.53-7.96 14.29-7.96 8.39 0 17.45 4.98 17.45 19.57v29.54Z"
      />
    </symbol>
    <symbol id="social-youtube" viewBox="0 0 100 100">
      <title>YouTube</title>
      <path d="M97.8,26.01c-1.15-4.29-4.52-7.67-8.81-8.81-7.77-2.08-38.95-2.08-38.95-2.08,0,0-31.17,0-38.95,2.08-4.29,1.15-7.67,4.52-8.81,8.81C.2,33.78.2,50,.2,50c0,0,0,16.22,2.08,23.99,1.15,4.29,4.52,7.67,8.81,8.81,7.77,2.08,38.95,2.08,38.95,2.08,0,0,31.17,0,38.95-2.08,4.29-1.15,7.67-4.52,8.81-8.81,2.08-7.77,2.08-23.99,2.08-23.99,0,0,0-16.22-2.08-23.99ZM40.07,64.95v-29.91l25.9,14.95-25.9,14.95Z" />
    </symbol>
    <symbol id="social-youtube-white" viewBox="0 0 100 100">
      <title>YouTube</title>
      <path d="M97.8,26.01c-1.15-4.29-4.52-7.67-8.81-8.81-7.77-2.08-38.95-2.08-38.95-2.08,0,0-31.17,0-38.95,2.08-4.29,1.15-7.67,4.52-8.81,8.81C.2,33.78.2,50,.2,50c0,0,0,16.22,2.08,23.99,1.15,4.29,4.52,7.67,8.81,8.81,7.77,2.08,38.95,2.08,38.95,2.08,0,0,31.17,0,38.95-2.08,4.29-1.15,7.67-4.52,8.81-8.81,2.08-7.77,2.08-23.99,2.08-23.99,0,0,0-16.22-2.08-23.99ZM40.07,64.95v-29.91l25.9,14.95-25.9,14.95Z" />
    </symbol>
  </svg>
)
