import React, { useState } from 'react'
import { MaterialIcon } from '../MaterialIcon/MaterialIcon'
import { MaterialIconId } from '../MaterialIcon/MaterialIconId.type'
import { MaterialIconSize } from '../MaterialIcon/MaterialIconSize.type'
import { SearchBox } from '../SearchBox/SearchBox'
import { MostVisitedLinks } from '../SearchPanel/MostVisitedLinks'
import { SearchPanelProps } from '../SearchPanel/SearchPanel'
import { MenuItem } from '../../types/MenuItem.type'
import { AppMenuMobileMenuItem } from './AppMenuMobileMenuItem'
import './style.module.scss'

export type AppMenuMobileProps = {
  className?: string
  isOpen: boolean
  searchPanel?: SearchPanelProps
  menuItems?: MenuItem[]
  mobileBottomMenuItems: MenuItem[]
  mobileNavSectionRef: React.RefObject<HTMLDivElement>
  testId?: string
}

// TODO
// find an advisor CTA button
// Login button
// personalized experience
export const AppMenuMobile = (props: AppMenuMobileProps) => {
  const {
    className,
    isOpen,
    searchPanel,
    menuItems,
    mobileBottomMenuItems,
    mobileNavSectionRef,
    testId
  } = props
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [navigationTree, setNavigationTree] = useState<number[]>([])

  function getMenuItems(
    arr: MenuItem[],
    indexes: number[]
  ): MenuItem[] | undefined {
    let value = arr
    for (const index of indexes) {
      if (value[index].menuItems !== undefined) {
        value = value[index].menuItems!
      } else {
        return undefined
      }
    }
    return value
  }

  const handleSearchClick = () => {
    setIsSearchOpen(prev => !prev)
  }

  const handleBackClick = () => {
    setIsSearchOpen(false)
    setNavigationTree([])
  }

  const updateNavigationTree = (
    event: React.KeyboardEvent<HTMLAnchorElement> | React.MouseEvent,
    newIndexes: number[]
  ) => {
    setNavigationTree(previousIndexes => {
      const isSameIndex =
        JSON.stringify(previousIndexes) === JSON.stringify(newIndexes)

      // Collapse tier 2 menu on close
      if (newIndexes.length === 2 && isSameIndex) {
        event.preventDefault()
        return newIndexes.slice(0, -1)
      }

      const subMenuItems = getMenuItems(menuItems!, newIndexes)

      // Return previous indexes if there are no sub menu options
      if (subMenuItems && subMenuItems.length === 0) {
        return previousIndexes
      }

      // Expand tier 1 menu if it has children and is not open
      if (previousIndexes.length === 0 && subMenuItems) {
        event.preventDefault()
        return newIndexes
      }

      // Disable link if not tier 2 and has children
      if (
        !isSameIndex &&
        newIndexes.length !== 1 &&
        subMenuItems?.length !== 0
      ) {
        event.preventDefault()
      }

      return newIndexes
    })
  }

  const handleMenuKeyDown = (
    event: React.KeyboardEvent<HTMLAnchorElement>,
    indexes: number[]
  ) => {
    if (event.key === 'Enter') {
      updateNavigationTree(event, indexes)
    }
  }

  const handleMenuClick = (event: React.MouseEvent, indexes: number[]) => {
    updateNavigationTree(event, indexes)
  }

  if (!isOpen) {
    return null
  }

  return (
    <div
      className={`AppMenuMobile u-block u-flexAlignSelfStretch u-bgColorGrey u-borderTop u-borderColorNeutral ${className}`}
      ref={mobileNavSectionRef}
    >
      <ul className="AppMenuMobile-tier1 AppMenuMobile-menuItems List List--selectable u-decoBorderResetBottom u-margin0 u-bgColorWhite">
        {(navigationTree[0] !== undefined || isSearchOpen) && (
          <li>
            <button
              className="Link AppMenuMobile-backButton u-linkClean u-flex u-sizeFull u-paddingVert3gu u-paddingLeft10gu u-paddingRight4gu u-bgColorWhite u-decoBorderLight"
              onClick={handleBackClick}
              data-testid={testId ? `${testId}-back-button` : null}
            >
              <span className="u-marginLeft1gu u-textSizeMsrm1">Back</span>
            </button>
          </li>
        )}
        {!isSearchOpen && menuItems && (
          <>
            {menuItems.map(
              (tier1Item, tier1Index) =>
                (navigationTree[0] === undefined ||
                  navigationTree[0] === tier1Index) && (
                  <AppMenuMobileMenuItem
                    key={tier1Item.name}
                    tier1Item={tier1Item}
                    tier1Index={tier1Index}
                    navigationTree={navigationTree}
                    handleMenuClick={handleMenuClick}
                    handleMenuKeyDown={handleMenuKeyDown}
                  />
                )
            )}
          </>
        )}
      </ul>
      {searchPanel && isSearchOpen && (
        <div className="u-sizeConstrained">
          {searchPanel.searchBox && (
            <div className="u-paddingTop9gu">
              <SearchBox {...searchPanel.searchBox} />
            </div>
          )}
          {searchPanel.mostVisitedLinks && (
            <div className="u-paddingTop14gu">
              <MostVisitedLinks {...searchPanel.mostVisitedLinks} />
            </div>
          )}
        </div>
      )}
      {!isSearchOpen && (
        <div className="u-sizeConstrained">
          <ul className="List List--selectable u-decoBorderResetBottom u-margin0 u-sm-paddingVert5gu u-sm-paddingHoriz3gu u-md-paddingVert5gu u-md-paddingHoriz3gu u-textCenter">
            {searchPanel?.searchBox && (
              <li className="List-item">
                <button
                  className="u-textColorActionBlue u-textSizeMsrm1"
                  onClick={handleSearchClick}
                >
                  <MaterialIcon
                    className="u-marginRight2gu"
                    style={{ paddingTop: '3px' }}
                    icon={MaterialIconId.SEARCH}
                    size={MaterialIconSize.SIZE_16}
                    ariaLabel="search"
                  />
                  Search
                </button>
              </li>
            )}
            {mobileBottomMenuItems?.map((navItem, index) => (
              <li key={index} className="List-item u-sm-textSizeMsrm1">
                <a href={navItem.url}>{navItem.name}</a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
