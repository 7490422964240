import React from 'react'
import './style.module.scss'
import { MostVisitedLinks, MostVisitedLinksProps } from './MostVisitedLinks'
import { SearchBox, SearchBoxProps } from '../SearchBox/SearchBox'

export type SearchPanelProps = {
  open: boolean
  searchBox: SearchBoxProps
  mostVisitedLinks?: MostVisitedLinksProps
  handleSearchClose?: () => void
}

export const SearchPanel = (props: SearchPanelProps) => {
  const { open, searchBox, mostVisitedLinks, handleSearchClose } = props

  if (!open) {
    return null
  }

  return (
    <>
      <div className="SearchPanel u-hidden u-lg-block u-bgColorGrey u-paddingBottom13gu">
        {searchBox && (
          <div className="u-sizeConstrained u-paddingTop9gu u-marginCenter u-flex">
            <div className="u-marginRight2gu u-sm-sizeFull u-md-size8of12 u-lg-size6of12 u-md-before2of12 u-lg-before3of12">
              <SearchBox {...searchBox} />
            </div>
            <div className="u-sm-hidden u-md-size2of12 u-lg-size3of12 u-textCenter">
              <button
                className="SearchPanel-closeButton"
                onClick={handleSearchClose}
              >
                <span
                  className="adel-material-symbols u-textColorNeutral"
                  aria-hidden="true"
                  title="Close"
                >
                  close
                </span>
              </button>
            </div>
          </div>
        )}
        {mostVisitedLinks && (
          <div className="u-paddingTop14gu">
            <MostVisitedLinks {...mostVisitedLinks} />
          </div>
        )}
      </div>
      {open && (
        <div
          onClick={handleSearchClose}
          className="SearchPanel-overlay u-hidden u-lg-block"
        ></div>
      )}
    </>
  )
}
