import React from 'react'
import xss from 'xss'
import { MenuItem } from '../../types/MenuItem.type'

export type AppMenuMobileMenuItemProps = {
  tier1Item: MenuItem
  tier1Index: number
  navigationTree: number[]
  handleMenuClick: (event: React.MouseEvent, indexes: number[]) => void
  handleMenuKeyDown: (
    event: React.KeyboardEvent<HTMLAnchorElement>,
    indexes: number[]
  ) => void
}

export const AppMenuMobileMenuItem = (props: AppMenuMobileMenuItemProps) => {
  const {
    tier1Index,
    tier1Item,
    navigationTree,
    handleMenuClick,
    handleMenuKeyDown
  } = props
  return (
    <li
      key={tier1Item.name}
      className={`AppMenuMobile-tier1MenuItem List-item u-decoBorderLight u-decoBorderResetTop u-padding0 u-textSizeMsrm1 ${
        navigationTree[0] === tier1Index ? 'is-open' : 'is-closed'
      }`}
    >
      <a
        className={`Link u-textColorActionBlue u-posRelative u-paddingVert3gu u-paddingHoriz4gu u-textColorDefault u-block u-flex u-flexJustifyBetween 
  ${navigationTree[0] === tier1Index ? 'is-open' : 'is-closed'}
  ${
    tier1Item.menuItems && tier1Item.menuItems?.length > 0
      ? 'is-expandable'
      : ''
  }
`}
        rel="noopener noreferrer"
        href={xss(tier1Item.url)}
        onKeyDown={event => handleMenuKeyDown(event, [tier1Index])}
        onClick={event => handleMenuClick(event, [tier1Index])}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: xss(tier1Item.name)
          }}
        />
      </a>
      {tier1Item.menuItems && (
        <ul
          className={`AppMenuMobile-tier2 List-item u-decoBorderLight u-decoBorderResetBottom u-padding0 ${
            navigationTree[0] === tier1Index ? 'is-open' : 'is-closed'
          }`}
        >
          {tier1Item.menuItems.map((tier2Item, tier2Index) => (
            <li
              key={tier2Item.name}
              className={`AppMenuMobile-tier2MenuItem List-item u-decoBorderLight u-decoBorderResetTop u-padding0 ${
                navigationTree[1] === tier2Index ? 'is-open' : 'is-closed'
              }`}
            >
              <a
                className={`Link u-textColorActionBlue u-posRelative u-paddingVert3gu u-paddingHoriz4gu u-textColorDefault u-block u-textSizeMsrm1 u-paddingLeft10gu ${
                  tier2Item.menuItems && tier2Item.menuItems?.length > 0
                    ? 'is-expandable'
                    : ''
                }`}
                rel="noopener noreferrer"
                href={xss(tier2Item.url)}
                onKeyDown={event =>
                  handleMenuKeyDown(event, [tier1Index, tier2Index])
                }
                onClick={event =>
                  handleMenuClick(event, [tier1Index, tier2Index])
                }
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: tier2Item.name
                  }}
                />
              </a>
              {tier2Item.menuItems && (
                <ul
                  className={`AppMenuMobile-tier3 List-item u-decoBorderLight u-decoBorderResetBottom u-padding0  ${
                    navigationTree[1] === tier2Index ? 'is-open' : 'is-closed'
                  }`}
                >
                  {tier2Item.menuItems.map(tier3Item => (
                    <li
                      key={tier3Item.name}
                      className="AppMenuMobile-tier3MenuItem List-item u-padding0"
                    >
                      <a
                        className={`AppMenuMobile-tier3MenuItem Link u-textColorActionBlue u-paddingLeft10gu u-posRelative u-paddingVert3gu u-paddingHoriz4gu u-textColorDefault u-textSizeMsrm1 u-block ${
                          navigationTree[1] === tier2Index ? 'is-open' : ''
                        }`}
                        rel="noopener noreferrer"
                        href={xss(tier3Item.url)}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: tier3Item.name
                          }}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </li>
  )
}
