import { notifyAnalytics } from '../Utility/PersonalizedButton'
import xss from 'xss'

const PersonalizedMobileButton = data => {
  const safeUrl = xss(data.url)
  const safeName = xss(data.name)
  const safePhone = xss(data.phonenumber)

  const container = document.createElement('div')
  container.setAttribute('id', 'personalized-mobile-nav')
  container.classList.add('u-flexAuto')

  const hr = document.createElement('hr')
  hr.classList.add(
    'Rule',
    'u-marginLeft3gu',
    'u-marginRight3gu',
    'u-lg-hidden',
    'amp-nav-divider'
  )

  const div = document.createElement('div')
  div.classList.add('u-marginLeft4gu', 'u-marginRight4gu', 'u-marginTop6gu')

  const mobileButton = document.createElement('a')
  mobileButton.setAttribute('id', 'personalized-mobile-button')
  if (data.email) {
    mobileButton.setAttribute(
      'href',
      safeUrl + '?openFormModal=request-contact'
    )
    mobileButton.innerText = 'Request Consultation'
    mobileButton.addEventListener(
      'click',
      notifyAnalytics.bind(
        undefined,
        'personalized nav',
        'Request consultation'
      )
    )
  } else {
    mobileButton.setAttribute('href', 'tel:' + safePhone)
    mobileButton.innerText = 'Call Advisor'
    mobileButton.addEventListener(
      'click',
      notifyAnalytics.bind(undefined, 'personalized nav', 'Call')
    )
  }
  mobileButton.classList.add(
    'Button',
    'Button--primary',
    'u-sizeFull',
    'u-sm-textSizeMsrm1'
  )

  const p = document.createElement('p')
  p.classList.add('u-marginBottom6gu', 'u-textCenter', 'u-sm-textSizeMsrm1')
  p.innerHTML =
    'Take the first step to a confident financial future.' +
    ` Contact <b>${safeName}</b>`

  div.appendChild(p)
  div.appendChild(mobileButton)
  container.appendChild(hr)
  container.appendChild(div)

  return container
}

export default PersonalizedMobileButton
