export default function dynamicAddMenus(
  cmsMenu,
  lastAmpAuthState,
  menuType,
  objName,
  defaultMenu,
  createMenusFromJsonDataFn
) {
  const dataSite = document.querySelector(objName).getAttribute('data-api-path')
  const isPreview = dataSite.includes('preview')
  const menuVersion = document
    .querySelector(objName)
    .getAttribute('data-version')
  const menuOptions = document
    .querySelector(objName)
    .getAttribute('data-menuoptions')
  const menuNames = document
    .querySelector(objName)
    .getAttribute('data-menunames')
  let menuToUse = cmsMenu
    ? cmsMenu.split('-')[cmsMenu.split('-').length - 1]
    : lastAmpAuthState
  menuToUse =
    menuToUse && menuOptions.split(',').includes(menuToUse)
      ? menuToUse
      : 'prospect'
  const menuIndex = menuOptions.split(',').indexOf(menuToUse)
  const storageKey = menuType + menuToUse
  const storageJSON = getSessionStorage(storageKey)

  let handledByStorage = false
  if (cmsMenu === null && storageJSON !== null && !isPreview) {
    // cms should always ignore cache to allow preview of edits
    try {
      const storageData = JSON.parse(storageJSON)
      const storageVersion = storageData.version
      if (menuVersion === storageVersion) {
        handleMenuJsonData(
          storageData,
          storageKey,
          menuVersion,
          createMenusFromJsonDataFn
        )
        handledByStorage = true
      }
    } catch (e) {
      // nothing to do - fail to loading by fetch
    }
  }
  if (!handledByStorage) {
    notHandledByStorage(
      defaultMenu,
      createMenusFromJsonDataFn,
      dataSite,
      menuNames,
      menuIndex,
      isPreview,
      menuVersion,
      storageKey
    )
  }
}

function notHandledByStorage(
  defaultMenu,
  createMenusFromJsonDataFn,
  dataSite,
  menuNames,
  menuIndex,
  isPreview,
  menuVersion,
  storageKey
) {
  const nameList = menuNames ? menuNames.split(',') : [defaultMenu]
  const menuName =
    nameList.length > menuIndex ? nameList[menuIndex] : nameList[0]
  const fetchUrl = dataSite + menuName
  if (fetchUrl.includes('_cmsinternal')) {
    fetch(fetchUrl)
      .then(response => response.text())
      .then(data => {
        const dataStart = data.indexOf('{') // CMS freemarker fetch has <div> tags prepended skip them
        const dataEnd = data.indexOf('<!--') // CMS freemarker fetch has comments appended - ignore them
        const mainData = data.substring(
          dataStart,
          dataEnd > 0 ? dataEnd : data.length
        )
        const jsonData = JSON.parse(mainData)
        handleMenuJsonData(jsonData, storageKey, 0, createMenusFromJsonDataFn)
      })
  } else {
    fetch(fetchUrl, { credentials: 'include' })
      .then(response => response.json())
      // if it is a preview set version to 0 so that flipping back to regular we don't show cached preview version
      .then(data =>
        handleMenuJsonData(
          data,
          storageKey,
          isPreview ? 0 : menuVersion,
          createMenusFromJsonDataFn
        )
      )
  }
}

function getSessionStorage(storageKey) {
  try {
    return sessionStorage.getItem(storageKey)
  } catch (error) {
    // ignore - we'll have to reload every time
  }
}

function handleMenuJsonData(
  data,
  storageKey,
  menuVersion,
  createMenusFromJsonDataFn
) {
  if (menuVersion !== null) {
    data.version = menuVersion
    try {
      sessionStorage.setItem(storageKey, JSON.stringify(data))
    } catch (error) {
      // ignore error and reload every time
    }
  }
  createMenusFromJsonDataFn(data)
}
