import React, { useEffect, useState } from 'react'
import stemmer from 'stemmer'
import xss from 'xss'

export type SearchBoxResultProps = {
  searchUrl: string
  keyword: string
  results: string[]
  focusedIndex: number
  testId?: string
}

export const SearchBoxResults = (props: SearchBoxResultProps) => {
  const { searchUrl, keyword, results, focusedIndex, testId } = props
  const [keywordStem, setKeywordStem] = useState('')

  useEffect(() => {
    setKeywordStem(stemmer(keyword))
  }, [keyword])

  if (!keyword || !results || results.length === 0) {
    return null
  }

  return (
    <ul
      className="SearchBox-results search-result-box active"
      data-testid={testId ? `${testId}-list` : null}
    >
      {results.map((item, index) => {
        const url = new URL(searchUrl)
        url.searchParams.append('sp_q', xss(item))

        const regex = new RegExp(`(${keyword}|${keywordStem})`, 'i')
        const highlightedItem = item.replace(regex, '<b>$1</b>')
        return (
          <li
            className={`SearchBox-resultItem${
              index == focusedIndex ? ' is-active' : ''
            }`}
            key={item}
            data-testid={testId ? `${testId}-${index}` : null}
          >
            <a
              href={xss(url.toString())}
              tabIndex={-1}
              dangerouslySetInnerHTML={{ __html: xss(highlightedItem) }}
            />
          </li>
        )
      })}
    </ul>
  )
}
