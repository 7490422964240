import { useState, useEffect } from 'react'

export const getScriptNonce = () => window?.NONCE ?? null

export const useFooterPrivacyLink = () => {
  const [showOptOut, setShowOptOut] = useState(false)
  const host = window.location.hostname
  const pathname = window.location.pathname
  const isNewsroomPath = /\/newsroom/.test(pathname)
  const isEnabledForHost =
    (/localhost/.test(host) ||
      /www\.qa\.ameriprise\.com/.test(host) ||
      /www\.ameriprise\.com/.test(host) ||
      /www\.qa\.ameripriseadvisors\.com/.test(host) ||
      /www\.ameripriseadvisors\.com/.test(host)) &&
    !isNewsroomPath

  useEffect(() => {
    window.geofeed = options => {
      const country = options.country.toString()
      const state = options.state.toString()

      if (state === 'CA' && country === 'US') {
        setShowOptOut(true)
      }
    }
  }, [showOptOut])

  if (isEnabledForHost) {
    const onetrustScript = document.createElement('script')
    onetrustScript.setAttribute(
      'src',
      'https://geolocation.onetrust.com/cookieconsentpub/v1/geo/location/geofeed'
    )
    onetrustScript.nonce = getScriptNonce()
    document.head.appendChild(onetrustScript)
  }

  if (!isEnabledForHost) {
    return false
  }

  return showOptOut
}
