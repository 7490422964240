export class SitNavAnalytics {
  setSatelliteTrack(message = '') {
    // @ts-expect-error window._satellite does not exist
    if (window._satellite && typeof window._satellite.track === 'function') {
      // @ts-expect-error window._satellite does not exist
      message && window._satellite.track(message)
    }
    return true
  }

  ping(message: string, pingSrcType = 'link', pingType = 'triggerAnalytics') {
    // first check to make sure its a valid message string
    if (!message || typeof message !== 'string' || message.length < 1) {
      console.warn(
        'analytics.ping() received an invalid message. message must be a string.'
      )
      return
    }
    // also check to make sure it is a valid ping source type string
    if (
      !pingSrcType ||
      typeof pingSrcType !== 'string' ||
      pingSrcType.length < 1
    ) {
      console.warn(
        'analytics.ping() received an invalid Ping Source Type. Ping Source Type must be a string.'
      )
      return
    } else {
      pingSrcType = pingSrcType.toLowerCase()
    }
    // ping the analytics framework using the correct message delivery subsystem.
    this.pingCustomEvent(message, pingSrcType, pingType)
  }

  /**
   * Pings a custom event with the given message, source type, and event type.
   * @param {string} MESSAGE - The message to send.
   * @param {string} PING_SRC_TYPE - The source type of the ping.
   * @param {string} TYPE_ANALYTICS_CUSTOM_EVENT - The type of the custom event.
   */
  pingCustomEvent(
    MESSAGE: string,
    PING_SRC_TYPE: string,
    TYPE_ANALYTICS_CUSTOM_EVENT: string
  ) {
    let activityEvent
    const detail = { detail: {} }
    detail.detail = { message: MESSAGE, type: PING_SRC_TYPE }
    try {
      activityEvent = new CustomEvent(TYPE_ANALYTICS_CUSTOM_EVENT, detail)
    } catch (err) {
      // CustomEvent is not supported in IE browsers, so we do it the old way for them.
      const bubbles = false
      const cancelable = false
      activityEvent = document.createEvent('CustomEvent')
      activityEvent.initCustomEvent(
        TYPE_ANALYTICS_CUSTOM_EVENT,
        bubbles,
        cancelable,
        detail
      )
    }
    document.body.dispatchEvent(activityEvent)
  }
}
