import React from 'react'
import { MaterialIconId } from './MaterialIconId.type'
import { MaterialIconSize } from './MaterialIconSize.type'

export type MaterialIconProps = {
  icon: MaterialIconId
  className?: string
  size?: MaterialIconSize
  style?: React.CSSProperties
  ariaLabel?: string
  testId?: string
}

export const MaterialIcon = (props: MaterialIconProps) => {
  const { icon, style, size, className, ariaLabel, testId } = props

  const classList = []

  switch (size) {
    case MaterialIconSize.SIZE_14:
      classList.push('adel-material-symbols-14')
      break
    case MaterialIconSize.SIZE_16:
      classList.push('adel-material-symbols-16')
      break
    case MaterialIconSize.SIZE_18:
      classList.push('adel-material-symbols-18')
      break
    case MaterialIconSize.SIZE_20:
      classList.push('adel-material-symbols-20')
      break
    case MaterialIconSize.SIZE_24:
      classList.push('adel-material-symbols-24')
      break
    case MaterialIconSize.SIZE_36:
      classList.push('adel-material-symbols-36')
      break
    default:
      classList.push('adel-material-symbols')
      break
  }

  if (className) {
    classList.push(className)
  }

  return (
    <span
      className={classList.join(' ')}
      style={style}
      data-testid={testId ? `${testId}-icon` : null}
      aria-label={ariaLabel}
    >
      {icon}
    </span>
  )
}

export default MaterialIcon
