import React, { useEffect, useRef, useState } from 'react'
import { SearchPanel, SearchPanelProps } from '../SearchPanel/SearchPanel'
import { MaterialIconId } from '../MaterialIcon/MaterialIconId.type'
import MaterialIcon from '../MaterialIcon'
import { MaterialIconSize } from '../MaterialIcon/MaterialIconSize.type'
import { MenuItem } from '../../types/MenuItem.type'
import xss from 'xss'
import './style.module.scss'
import BrandIcon from '../BrandIcon'
import { BrandIconId } from '../BrandIcon/BrandIconId.type'
import { Link } from '../../types/Link.type'

export type AppMenuProps = {
  className?: string
  menuItems: MenuItem[]
  searchPanel?: SearchPanelProps
  primaryButton?: Link
  secondaryButton?: Link
  testId?: string
}

export const AppMenu = (props: AppMenuProps) => {
  const {
    className,
    menuItems,
    searchPanel,
    primaryButton,
    secondaryButton,
    testId
  } = props
  const [navigationTree, setNavigationTree] = useState<number[]>([])
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const [lastScrollY, setLastScrollY] = useState(0)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const location = window.location.href

  const handleScroll = () => {
    const currentScrollY = window.scrollY
    const rect = menuRef.current?.getBoundingClientRect()
    if (!rect) {
      return
    }

    if (window.scrollY > rect?.bottom) {
      setIsSticky(true)
    } else {
      setIsSticky(false)
    }

    // shy behavior
    if (currentScrollY > lastScrollY) {
      setIsSticky(false)
    }

    setIsSearchOpen(false)
    setLastScrollY(currentScrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  useEffect(() => {
    if (isSearchOpen) {
      // TODO forward ref to avoid querying DOM
      const searchInput = document.querySelector('input[name="sp_q"]')
      if (searchInput instanceof HTMLInputElement) {
        searchInput.focus()
      }
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsSearchOpen(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isSearchOpen])

  function getMenuItems(
    arr: MenuItem[],
    indexes: number[]
  ): MenuItem[] | undefined {
    let value = arr
    for (const index of indexes) {
      if (value[index].menuItems !== undefined) {
        value = value[index].menuItems!
      } else {
        return undefined
      }
    }
    return value
  }

  const handleMenuKeyDown = (
    event: React.KeyboardEvent<HTMLAnchorElement>,
    indexes: number[]
  ) => {
    if (event.key === 'Enter') {
      setNavigationTree(indexes)
      const subMenuOptions = getMenuItems(menuItems, indexes)
      if (subMenuOptions?.length !== 0) {
        event.preventDefault()
      }
    }
  }

  const handleMenuMouseEnter = (indexes: number[]) => {
    setNavigationTree(indexes)
  }

  const handleMenuItemMouseOver = () => {
    setIsSearchOpen(false)
  }

  const handleSearchClick = () => {
    setNavigationTree([])
    setIsSearchOpen(prev => !prev)
  }

  const handleSearchClose = () => {
    setIsSearchOpen(false)
  }

  const handleBlur = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      setNavigationTree([])
    }, 1000)
  }

  const handleFocus = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  }

  // desktop version
  return (
    <div
      className={`AppMenu ${
        isSticky ? 'AppMenu--sticky' : ''
      } u-bgColorGrey ${className}`}
      data-testid={testId}
    >
      <nav
        className="u-sizeConstrained"
        ref={menuRef}
        onMouseEnter={handleFocus}
        onMouseLeave={handleBlur}
      >
        <ul className="AppMenu-tier1 AppMenu-menuItems List List--selectable List--inline u-flexJustifyStart u-decoBorderResetBottom u-padding0 u-margin0">
          {menuItems?.map((tier1Item, tier1Index) => (
            <li
              key={tier1Item.name}
              className={`AppMenu-tier1MenuItem AppMenu-link u-padding0 
                  ${navigationTree[0] === tier1Index ? 'is-open' : ''}
                  ${location === tier1Item.url ? 'is-active' : ''}
                `}
              onFocus={() =>
                tier1Index !== navigationTree[0] && setNavigationTree([])
              }
              onMouseOver={() => handleMenuItemMouseOver()}
            >
              <a
                href={xss(tier1Item.url)}
                className="u-flex u-paddingHoriz0 u-paddingVert3gu u-marginHoriz4gu u-textSizeMsr u-textNoWrap"
                onKeyDown={e => handleMenuKeyDown(e, [tier1Index])}
                onMouseEnter={() => handleMenuMouseEnter([tier1Index])}
              >
                {isSticky && tier1Item.name === 'Home' ? (
                  <BrandIcon
                    className="AppMenu-ampfCompass"
                    icon={BrandIconId.AMPF_COMPASS}
                  />
                ) : (
                  <span
                    dangerouslySetInnerHTML={{ __html: xss(tier1Item.name) }}
                  />
                )}
              </a>
              {tier1Item.menuItems && (
                <div className="AppMenu-tier2Wrapper">
                  <ul className="AppMenu-tier2">
                    {tier1Item.menuItems?.map((tier2Item, tier2Index) => (
                      <li
                        key={tier2Item.name}
                        className={`${
                          navigationTree[0] === tier1Index &&
                          navigationTree[1] === tier2Index
                            ? 'is-open'
                            : ''
                        }`}
                      >
                        <a
                          href={xss(tier2Item.url)}
                          onKeyDown={e =>
                            handleMenuKeyDown(e, [tier1Index, tier2Index])
                          }
                          onMouseEnter={() =>
                            handleMenuMouseEnter([tier1Index, tier2Index])
                          }
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: xss(tier2Item.name)
                            }}
                          />
                          {tier2Item.menuItems &&
                            tier2Item.menuItems?.length > 0 && (
                              <MaterialIcon
                                icon={MaterialIconId.CHEVRON_RIGHT}
                                size={MaterialIconSize.SIZE_18}
                                className="u-floatRight u-marginRight3gu"
                              />
                            )}
                        </a>
                        {tier2Item.menuItems &&
                          tier2Item.menuItems?.length > 0 && (
                            <div
                              className={`AppMenu-tier3Wrapper ${
                                tier1Item.menuItems &&
                                tier1Item.menuItems.length <
                                  tier2Index + tier2Item.menuItems.length &&
                                'AppMenu-tier3--overhang'
                              }`}
                            >
                              <ul className="AppMenu-tier3">
                                {tier2Item.menuItems?.map(
                                  (tier3Item, tier3Index) => (
                                    <li key={tier3Item.name}>
                                      <a
                                        href={xss(tier3Item.url)}
                                        onKeyDown={e =>
                                          handleMenuKeyDown(e, [
                                            tier1Index,
                                            tier2Index,
                                            tier3Index
                                          ])
                                        }
                                        onMouseEnter={() =>
                                          handleMenuMouseEnter([
                                            tier1Index,
                                            tier2Index,
                                            tier3Index
                                          ])
                                        }
                                      >
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: xss(tier3Item.name)
                                          }}
                                        />
                                      </a>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
          <li className="u-padding0 u-flexExpandLeft">
            <ul className="u-flex u-flexAlignItemsCenter u-flexJustifyBetween">
              {searchPanel && (
                <li className="AppMenu-link u-padding0">
                  <button
                    onClick={handleSearchClick}
                    className={`Link u-flex u-flexJustifyBetween u-paddingHoriz0 u-paddingVert3gu u-marginLeft4gu u-textSizeMsr u-textNoWrap ${
                      isSearchOpen ? 'is-open' : ''
                    }`}
                  >
                    {isSticky ? (
                      <MaterialIcon
                        icon={MaterialIconId.SEARCH}
                        size={MaterialIconSize.SIZE_16}
                      />
                    ) : (
                      'Search'
                    )}
                  </button>
                </li>
              )}
              {isSticky && primaryButton && (
                <li className="AppMenu-link u-padding0 u-margin0">
                  <a
                    href={primaryButton.url}
                    target={primaryButton?.target}
                    className="Button Button--primary Button--small u-marginLeft4gu"
                    data-testid={testId ? `${testId}-primary-button` : null}
                  >
                    {primaryButton.name}
                  </a>
                </li>
              )}
              {isSticky && secondaryButton && (
                <li className="AppMenu-link">
                  <a
                    href={secondaryButton.url}
                    target={secondaryButton?.target}
                    className="Button Button--secondary--action-blue Button--small u-marginLeft4gu"
                    data-testid={testId ? `${testId}-secondary-button` : null}
                  >
                    {secondaryButton.name}
                  </a>
                </li>
              )}
            </ul>
          </li>
        </ul>
      </nav>
      {searchPanel && (
        <SearchPanel
          open={isSearchOpen}
          searchBox={{
            searchUrl: searchPanel.searchBox?.searchUrl,
            searchAutocompleteUrl: searchPanel.searchBox.searchAutocompleteUrl,
            testId: searchPanel.searchBox.testId
          }}
          mostVisitedLinks={searchPanel.mostVisitedLinks}
          handleSearchClose={handleSearchClose}
        />
      )}
    </div>
  )
}
