import React, { forwardRef, MouseEventHandler } from 'react'
import './style.module.scss'

export type MenuButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  open: boolean
  className?: string
  onClick: MouseEventHandler
  testId?: string
}

export const MenuButton = forwardRef<HTMLButtonElement, MenuButtonProps>(
  ({ open, onClick, testId, className }, ref) => {
    return (
      <button
        className={`MenuButton ${className}`}
        onClick={onClick}
        data-testid={testId ? testId : null}
        ref={ref}
      >
        <div className="u-flex u-flexCol u-flexJustifyCenter u-flexAlignItemsCenter u-textColorActionBlue">
          {!open && (
            <>
              <span
                data-testid={testId ? `${testId}-open-icon` : null}
                className="MenuButton-hamburgerIcon adel-material-symbols u-textSizeMsrp6 u-flex u-flexJustifyCenter u-flexAlignItemsCenter"
                aria-label="Menu Icon"
                role="img"
              >
                menu
              </span>
              <span className="u-textColorActionBlue">Menu</span>
            </>
          )}
          {open && (
            <span
              data-testid={testId ? `${testId}-close-icon` : null}
              className="adel-material-symbols u-textSizeMsrp6 u-flex u-flexJustifyCenter u-flexAlignItemsCenter"
              aria-label="Close Icon"
              role="img"
            >
              close
            </span>
          )}
        </div>
      </button>
    )
  }
)
