/**
 * Should contain all Material Icons used on public site
 */
export enum MaterialIconId {
  ARROW_DOWNWARD = 'arrow_downward',
  ARROW_FORWARD = 'arrow_forward',
  ARROW_UPWARD = 'arrow_upward',
  CHECK = 'check',
  CHEVRON_LEFT = 'chevron_left',
  CHEVRON_RIGHT = 'chevron_right',
  CLOSE = 'close',
  INFO = 'info',
  KEYBOARD_CONTROL_KEY = 'keyboard_control_key',
  LINK = 'link',
  MAIL = 'mail',
  MENU = 'menu',
  MENU_OPEN = 'menu_open',
  NEW_WINDOW = 'new_window',
  PHONE = 'phone',
  SEARCH = 'search',
  SECURITY = 'security',
  WARNING = 'warning',
  PICTURE_AS_PDF = 'picture_as_pdf',
  EVENT_NOTE = 'event_note',
  KEYBOARD_ARROW_DOWN = 'keyboard_arrow_down',
  CHECK_PHONE = 'security_update_good'
}
