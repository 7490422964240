import AmeripriseLoaderElement from './AmeripriseLoader.element'

/*
 * Ameriprise Loader
 * Example: To display the SiteNav Header component in the page
 * <ameriprise-loader
 *  data-url="https://www-ws1.qa.ameriprise.com"
 *  data-component="header"
 * >
 * </ameriprise-loader>
 * <script type="text/javascript" src="https://www-ws1.qa.ameriprise.com/webfiles/latest/assets/ampsite/ameriprise-site-nav.js"></script>
 */
customElements.define('ameriprise-loader', AmeripriseLoaderElement)
