import { kebabCase } from '../StringFormat/kebabCase'

export class Analytics {
  private DATA_ANALYTICS_ATTRIBUTE = 'data-analytics'
  private DATA_TARGET_ANALYTICS_ATTRIBUTE = 'data-target-analytics'
  private scope: string
  private delimiter: string

  constructor(defaultName?: string, analyticName?: string, delimiter?: string) {
    this.scope = analyticName || defaultName || ''
    this.delimiter = delimiter || '-'
  }

  dataAnalyticAttributeValue = (value?: string) => {
    if (value === undefined) {
      return kebabCase(this.scope)
    }

    return kebabCase(`${this.scope}${this.delimiter}${value}`)
  }

  dataAnalyticAttribute = (value?: string) => {
    return {
      [this.DATA_ANALYTICS_ATTRIBUTE]: this.dataAnalyticAttributeValue(value)
    }
  }

  dataTargetAnalyticAttributeValue = (value?: string) => {
    if (value) {
      const dataAnalyticAttribute = this.dataAnalyticAttributeValue(value)

      return `${dataAnalyticAttribute}${this.delimiter}placeholder`
    }

    return kebabCase(`${this.scope}${this.delimiter}placeholder`)
  }

  dataTargetAnalyticAttribute = (
    defaultName?: string,
    analyticName?: string
  ) => {
    return {
      [this.DATA_TARGET_ANALYTICS_ATTRIBUTE]:
        this.dataTargetAnalyticAttributeValue(defaultName || analyticName)
    }
  }
}
