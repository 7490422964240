import { Link } from '../../types/Link.type'
import { CfgApiMenu } from '../../types/CfgApiMenu.type'
import { extractMenuItems } from './extractMenuItems'

export const fetchMenuData = async (
  menuApiPath: string,
  menuType: string
): Promise<CfgApiMenu> => {
  return fetch(`${menuApiPath}/${menuType}`, {
    credentials: 'include'
  })
    .then(response => {
      return response.json()
    })
    .catch(err => {
      console.error('failed to fetch ' + err.message)
    })
}

export const cacheMenuItems = (
  cacheId: string,
  menuItems: Link[],
  version: string
): void => {
  const cacheData = {
    version,
    menuItems
  }
  sessionStorage.setItem(cacheId, JSON.stringify(cacheData))
}

export const getCachedMenuItems = (
  cacheId: string,
  version: string
): Link[] | null => {
  const menuItemsCacheRaw = sessionStorage.getItem(cacheId)
  if (menuItemsCacheRaw) {
    const cacheData = JSON.parse(menuItemsCacheRaw)
    if (cacheData.version === version) {
      return cacheData.menuItems
    }
  }
  return null
}

// TODO add support to get menu while on CMS domain
export const getMenu = async (
  menuApiPath: string,
  menuType: string,
  depth: number,
  version: string
): Promise<Link[]> => {
  const cacheId = `${menuType}-cache`
  let menuItemsCache = getCachedMenuItems(cacheId, version)

  if (!menuItemsCache) {
    try {
      const body = await fetchMenuData(menuApiPath, menuType)
      menuItemsCache = extractMenuItems(body, depth)
      cacheMenuItems(cacheId, menuItemsCache, version)
    } catch (err) {
      const error = err as Error
      console.error('Failed to fetch menu data:', error.message)
      menuItemsCache = []
    }
  }

  return menuItemsCache
}
