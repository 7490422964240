import React, { MouseEventHandler } from 'react'
import { BrandIcon } from '../BrandIcon/BrandIcon'
import { BrandIconId } from '../BrandIcon/BrandIconId.type'
import { SocialMediaBarIcons } from './SocialMediaBarIcons'
import './style.module.scss'

export type SocialMediaBarProps = {
  content: {
    findAnAdvisorBtnText: string
    findAnAdvisorLink: string
    isFindAnAdvisorShown?: boolean
    handleClick?: MouseEventHandler
  }
  testId?: string
}

export const SocialMediaBar = (props: SocialMediaBarProps) => {
  const { content, testId } = props
  const {
    isFindAnAdvisorShown,
    findAnAdvisorBtnText,
    findAnAdvisorLink,
    handleClick
  } = content

  return (
    <div className="u-adel-reset u-pt-hidden">
      <SocialMediaBarIcons />
      <div className="SocialMediaBar  u-block u-bgColorBrandMidnight">
        <div className="u-sizeConstrained Grid">
          <div className="u-sm-hidden u-md-size6of12 u-lg-size6of12 u-marginVert2gu">
            <BrandIcon
              icon={BrandIconId.AMPF_COMPASS}
              style={{
                fill: '#fff',
                width: '46px',
                height: '46px',
                marginTop: '6px'
              }}
              spriteUrl={''}
              testId={testId ? `${testId}-ampf-icon` : undefined}
            />
          </div>
          <div className="u-sm-sizeFull u-md-size2of12 u-lg-size2of12 u-md-textLeft u-lg-textCenter u-lg-paddingRight10gu">
            {isFindAnAdvisorShown && (
              <div className="amp-advisorButton u-sm-marginTop3gu u-flexJustifyEnd u-paddingVert2gu">
                <a
                  onClick={handleClick}
                  data-analytics="Find an Advisor"
                  data-qa="FindAdvisorButton"
                  className="Button Button--primary Button--reversed  u-sm-block u-inline-block u-marginVert2gu u-textNoWrap analytics-FindAnAdvisor"
                  href={findAnAdvisorLink}
                  data-testid={testId ? `${testId}-find-an-advisor-btn` : null}
                  target="_blank"
                  rel="noreferrer"
                >
                  {findAnAdvisorBtnText}
                </a>
              </div>
            )}
          </div>
          <div className="u-paddingHoriz5gu u-sm-marginTop3gu u-sm-marginBottom3gu u-sm-sizeFull u-md-size4of12 u-lg-size4of12 u-flex u-flexJustifyBetween">
            <a
              onClick={handleClick}
              className="SocialMediaLink Link Link--reversed u-block u-marginVert2gu u-marginHoriz2gu u-padding2gu u-linkClean"
              href="https://www.linkedin.com/company/3032"
              rel="external noreferrer"
              target="_blank"
              data-testid={testId ? `${testId}-linkedin-link` : null}
            >
              <BrandIcon
                icon={BrandIconId.SOCIAL_LINKEDIN_WHITE}
                ariaLabel="LinkedIn"
                style={{
                  width: '32px',
                  height: '32px',
                  fill: '#fff',
                  strokeWidth: '0px'
                }}
                spriteUrl={''}
                testId={testId ? `${testId}-linkedin-icon` : undefined}
              />
            </a>
            <a
              onClick={handleClick}
              className="SocialMediaLink Link Link--reversed u-block u-marginVert2gu u-marginHoriz2gu u-padding2gu u-linkClean"
              href="https://facebook.com/ameriprise"
              rel="external noreferrer"
              target="_blank"
              data-testid={testId ? `${testId}-facebook-link` : null}
            >
              <BrandIcon
                icon={BrandIconId.SOCIAL_FACEBOOK_WHITE}
                ariaLabel="Facebook"
                style={{
                  width: '32px',
                  height: '32px',
                  fill: '#fff',
                  strokeWidth: '0px'
                }}
                spriteUrl={''}
                testId={testId ? `${testId}-facebook-icon` : undefined}
              />
            </a>
            <a
              onClick={handleClick}
              className="SocialMediaLink Link Link--reversed u-block u-marginVert2gu u-marginHoriz2gu u-padding2gu u-linkClean"
              href="https://youtube.com/Ameriprise"
              rel="external noreferrer"
              target="_blank"
              data-testid={testId ? `${testId}-youtube-link` : null}
            >
              <BrandIcon
                icon={BrandIconId.SOCIAL_YOUTUBE_WHITE}
                ariaLabel="YouTube"
                style={{
                  width: '32px',
                  height: '32px',
                  fill: '#fff',
                  strokeWidth: '0px'
                }}
                spriteUrl={''}
                testId={testId ? `${testId}-youtube-icon` : undefined}
              />
            </a>
            <a
              onClick={handleClick}
              className="SocialMediaLink Link Link--reversed u-block u-marginVert2gu u-marginHoriz2gu u-padding2gu u-linkClean"
              href="https://www.instagram.com/ameriprise/"
              rel="external noreferrer"
              target="_blank"
              data-testid={testId ? `${testId}-instagram-link` : null}
            >
              <BrandIcon
                icon={BrandIconId.SOCIAL_INSTAGRAM}
                ariaLabel="Instagram"
                style={{
                  width: '32px',
                  height: '32px',
                  fill: '#fff',
                  strokeWidth: '0px'
                }}
                spriteUrl={''}
                testId={testId ? `${testId}-instagram-icon` : undefined}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
