import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SearchBoxResults } from './SearchBoxResults'
import xss from 'xss'
import './style.module.scss'

export type DebouncedFunction = (value: string) => Promise<void>

export type SearchBoxProps = {
  label?: string
  searchUrl: string
  searchAutocompleteUrl: string
  testId?: string
}

export const SearchBox = (props: SearchBoxProps) => {
  const { label, searchUrl, searchAutocompleteUrl, testId } = props
  const [results, setResults] = useState<string[]>([])
  const [keyword, setKeyword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [resultFocusedIndex, setResultFocusedIndex] = useState(-1)
  const formRef = useRef<HTMLFormElement>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const debounce = (func: DebouncedFunction, delay: number) => {
    let timeoutId: NodeJS.Timeout
    return (...args: unknown[]) => {
      if (timeoutId) clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        func(...(args as [string]))
      }, delay)
    }
  }

  const fetchResults = async (value: string) => {
    if (value.trim() === '') {
      setResults([])
      return
    }

    try {
      const response = await fetch(
        `${searchAutocompleteUrl}${encodeURIComponent(value.trim())}`
      )
      if (response.ok) {
        const data = await response.json()
        setResults(data || [])
      } else {
        console.error('Error fetching search results')
      }
    } catch (error) {
      console.error('Error fetching search results')
    }
  }

  const debouncedFetchResults = useCallback(debounce(fetchResults, 300), [])

  const handleOnChange = () => {
    setResultFocusedIndex(-1)
    setErrorMessage('')
    if (searchInputRef?.current) {
      const value = searchInputRef.current.value
      setKeyword(value)
    }
  }

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        setResultFocusedIndex(prevIndex => (prevIndex + 1) % results.length)
        e.preventDefault()
        break
      case 'ArrowUp':
        setResultFocusedIndex(
          prevIndex => (prevIndex - 1 + results.length) % results.length
        )
        e.preventDefault()
        break
      case 'Enter':
        if (searchInputRef?.current && resultFocusedIndex >= 0) {
          searchInputRef.current.value = results[resultFocusedIndex]
        }
        e.preventDefault()
        handleSubmit()
        break
    }
  }

  useEffect(() => {
    if (keyword.trim() !== '') {
      debouncedFetchResults(keyword)
    }
  }, [keyword, debouncedFetchResults])

  const handleSubmit = (e?: React.FormEvent) => {
    const searchValue = searchInputRef.current?.value.trim() || ''
    if (searchValue === '') {
      e?.preventDefault()
      setErrorMessage('Please enter search term(s).')
      return
    }
    setErrorMessage('')
    if (formRef.current) {
      formRef.current.submit()
    }
  }

  const handleResultClose = () => {
    if (searchInputRef?.current) {
      searchInputRef.current.value = ''
      searchInputRef.current.focus()
    }
    setKeyword('')
    setResults([])
  }

  return (
    <div className="SearchBox" data-testid={testId ? `${testId}` : undefined}>
      <form
        method="get"
        action={searchUrl}
        className="SearchBox-form Form-group u-flex u-marginCenter"
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <div className="SearchBox-inputGroup Input-group u-marginRight2gu">
          <input
            type="text"
            name="sp_q"
            className="SearchBox-keyword Input Input--floatingLabel Input--withIcon Input--withIconLeft"
            placeholder=""
            autoComplete="off"
            onKeyDown={handleOnKeyDown}
            onChange={handleOnChange}
            ref={searchInputRef}
            data-testid={testId ? `${testId}-keyword` : null}
          />
          {label && (
            <label
              className="Input-label"
              data-testid={testId ? `${testId}-label` : null}
            >
              {xss(label)}
            </label>
          )}
          {errorMessage && (
            <ul className="u-posAbsolute">
              <li
                className="Form-help Form-help--error"
                data-testid={testId ? `${testId}-error-message` : null}
              >
                <span className="u-hiddenVisually">Error, </span>
                <span>{errorMessage}</span>
              </li>
            </ul>
          )}
          <span
            className="SearchBox-keywordIcon adel-material-symbols-20 Input-icon"
            aria-label="Search"
          >
            search
          </span>
          {keyword && (
            <button
              className="SearchBox-resultClearIcon u-block u-textSizeMsrp2"
              onClick={handleResultClose}
              data-testid={testId ? `${testId}-clear` : null}
            >
              <span className="adel-material-symbols-20">chevron_left</span>
            </button>
          )}
          <SearchBoxResults
            keyword={keyword}
            results={results}
            focusedIndex={resultFocusedIndex}
            searchUrl={searchUrl}
            testId={testId}
          />
        </div>
        <button
          type="submit"
          className="SearchBox-submitButton Button Button--primary u-hidden u-md-block u-lg-block"
          data-testid={testId ? `${testId}-search-button` : null}
        >
          Search
        </button>
      </form>
    </div>
  )
}
