import React, { useEffect, useState } from 'react'
import xss from 'xss'
import jsCookie from 'js-cookie'
import { SitNavAnalytics } from './SiteNavAnalytics'
import { useFooterPrivacyLink } from './useFooterPrivacyLink'
import { Link } from '../../types/Link.type'
import { getMenu } from '../../lib/CfgApi/getMenu'
import './style.module.scss'

export type FooterNavigationProps = {
  menuApiPath: string
  menuName?: string
  version?: string
  customerServiceHeading: string
  customerServiceDescription: string
  customerServiceLink: string
  testId?: string
}

export const FooterNavigation = (props: FooterNavigationProps) => {
  const {
    menuName,
    menuApiPath,
    version,
    customerServiceHeading,
    customerServiceDescription,
    customerServiceLink,
    testId
  } = props
  const [menuItems, setMenuItems] = useState<Link[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const showPrivacyLink = useFooterPrivacyLink()
  const host = window.location.hostname
  const isAmeripriseAdvisorsSite =
    /www\.qa\.ameripriseadvisors\.com/.test(host) ||
    /www\.ameripriseadvisors\.com/.test(host)

  useEffect(() => {
    // TODO move fetching out of presentation component
    function getMenuType() {
      if (menuName) {
        return menuName
      }

      const userStatus = jsCookie.get('lastAmpAuthState') ?? 'unrecognized'

      if (userStatus === 'active') {
        return 'footer-menu-client-active'
      }

      return 'footer-menu-prospect'
    }

    const fetchData = async () => {
      setIsLoading(true)

      const menuType = getMenuType()
      const navMenuItems = await getMenu(
        menuApiPath,
        menuType,
        1,
        version ?? '0'
      )
      setMenuItems(navMenuItems)
      setIsLoading(false)
    }

    fetchData()
  }, [menuApiPath, menuName, version])

  function handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
    try {
      // TODO determine whether this ping is currently being used by analytics team
      const footerAnalytics = new SitNavAnalytics()
      footerAnalytics.ping(event.currentTarget.text, 'footer')
    } catch (e) {
      console.log('Analytics ping failed')
    }
  }

  return (
    <div className="u-adel-reset FooterNavigation u-pt-hidden u-block">
      <div className="u-sizeConstrained u-paddingVert6gu u-bgColorWhite">
        <div className="Grid Grid--fill Grid--withGutter">
          <div className="u-sizeFill u-lg-size2of3">
            <nav role="navigation">
              {isLoading && (
                <div className="Loading u-textColorBrandLight">
                  <div className="Loading-indicator"></div>
                  <div className="Loading-indicator"></div>
                  <div className="Loading-indicator"></div>
                </div>
              )}
              {!isLoading && (
                <ul className="u-decoUnbulleted" data-qa="FooterNavLevelOne">
                  {menuItems?.map((menuItem, index) =>
                    menuItem.name !== 'Do Not Sell or Share My Personal Data' ||
                    showPrivacyLink ? (
                      <li
                        key={index}
                        className={
                          'u-marginBottom6gu' +
                          (menuItem.name === 'Customer Service'
                            ? ' u-lg-hidden'
                            : '')
                        }
                      >
                        <a
                          onClick={handleClick}
                          className="Link u-textBold u-textColorDefault"
                          href={
                            isAmeripriseAdvisorsSite &&
                            menuItem.name ===
                              'Do Not Sell or Share My Personal Data'
                              ? `/cookie-opt-out` // CPRA page on Advisor site
                              : xss(menuItem.url)
                          }
                        >
                          {xss(menuItem.name)}
                        </a>
                      </li>
                    ) : (
                      ''
                    )
                  )}
                </ul>
              )}
            </nav>
          </div>
          <div className="u-sm-hidden u-md-hidden u-lg-size1of3">
            <span>
              <span
                className="u-textPrimary u-textBold u-borderBottom u-borderColorNeutralLight u-paddingBottom2gu u-marginBottom2gu u-block"
                data-testid={
                  testId ? `${testId}-customer-service-heading` : undefined
                }
              >
                {customerServiceHeading}
              </span>
              <p
                className="u-marginBottom6gu u-textColorLight"
                data-testid={
                  testId ? `${testId}-customer-service-body` : undefined
                }
              >
                {customerServiceDescription}
              </p>
              <a
                className="Link"
                href={customerServiceLink}
                data-testid={
                  testId ? `${testId}-customer-service-link` : undefined
                }
              >
                Learn more
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
