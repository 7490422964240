/*
 *  Email validation
 */
export const isValidEmail = email => {
  const emailFormatted = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/
  return emailFormatted.test(email)
}

/*
 *  Zipcode validation
 */
export const isValidZipcode = zip => {
  const zipFormatted = /(^\d{5}$)|(^\d{5}-\d{4}$)/
  return zipFormatted.test(zip)
}

/*
 * Phone number Validation
 */
export const isValidPhoneNumber = (input, optional = true) => {
  const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  if (optional && (input?.trim() === '' || !input)) {
    return true
  }
  if (
    !optional ||
    (optional && input?.trim() !== '' && phoneRegex.test(input))
  ) {
    return true
  }
  return false
}

/* Utility function to format phone numbers */
export const formatPhoneNumber = input => {
  let formattedInput = ''
  const receivedInput = input.toString()
  if (receivedInput) {
    formattedInput = receivedInput
      .replace(/\D+/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  }
  return formattedInput
}

export const formatPhoneNumberForAriaLabel = tel => {
  let label = ''
  if (tel) {
    // Remove `tel:` protocol, insert spaces between the numbers
    tel = tel.split('tel:')[1].split('').join(' ')
    // Group the numbers, insert periods
    label = `${tel.slice(0, 5)}.${tel.slice(5, 11)}.${tel.slice(11)}`
  }
  return label
}

export const debounce = (func, delay) => {
  let timeout
  return (...args) => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => func(...args), delay)
  }
}
