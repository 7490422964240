import { CfgApiMenu, CfgApiMenuItem } from '../../types/CfgApiMenu.type'
import { MenuItem } from '../../types/MenuItem.type'

export const makeUrlRelative = (url: string): string => {
  const ameriprisePattern =
    /^https:\/\/www[0-9a-zA-Z-]*(\.[a-zA-Z0-9-]+)?\.ameriprise\.com/

  // return original URL if the current host is not ameriprise.com
  const currentHost = window.location.href
  if (!ameriprisePattern.test(currentHost)) {
    return url
  }

  // convert URL to relative if it matches ameriprise.com pattern
  if (ameriprisePattern.test(url)) {
    const urlObj = new URL(url)
    return urlObj.pathname
  }

  return url
}

export const extractMenuItems = (
  cfgApiMenu: CfgApiMenu,
  depth: number = Infinity
): MenuItem[] => {
  const simplifyMenu = (
    menuItems: CfgApiMenuItem[],
    currentDepth: number
  ): MenuItem[] => {
    if (currentDepth > depth) {
      return []
    }
    return menuItems.map(item => {
      const { name, url, menuItems } = item.HstMenuItemRepresentation
      return {
        name,
        url: makeUrlRelative(url),
        menuItems: simplifyMenu(menuItems, currentDepth + 1)
      }
    })
  }

  return simplifyMenu(cfgApiMenu.menuItems, 1)
}
