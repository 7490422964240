import React from 'react'
import { createRoot } from 'react-dom/client'
import { AppBar, getMenu } from '@ampcore/design-system'
import jsCookie from 'js-cookie'

export default class AppBarElement extends HTMLElement {
  async connectedCallback() {
    this.mountPoint = document.createElement('div')

    const logoHref = this.getAttribute('data-logo-href')
    const searchUrl = this.getAttribute('data-search-url')
    const searchAutocompleteUrl = this.getAttribute(
      'data-search-autocomplete-url'
    )
    const menuItemsUrl = this.getAttribute('data-menu-items-url')
    const menuItemsVersion = this.getAttribute('data-menu-items-version')
    const userStatus = jsCookie.get('lastAmpAuthState') ?? 'unrecognized'
    const menuItems = await getMenu(
      menuItemsUrl,
      userStatus === 'active' ? 'main-menu-client' : 'main-menu-prospect',
      3,
      menuItemsVersion
    )

    const shadowRoot = this.attachShadow({ mode: 'open' })
    shadowRoot.appendChild(this.mountPoint)

    this.template = this.querySelector('#app-bar-template')
    if (this.template && this.template.content) {
      shadowRoot.appendChild(this.template.content)
    }

    const root = createRoot(this.mountPoint)

    // TODO avoid hard coding dynamic props
    // hide DOM during page load
    root.render(
      <div className="u-adel-reset">
        <div className="u-block" style={{ display: 'none' }}>
          <AppBar
            logoHref={logoHref}
            primaryButton={{
              name: 'Find an Advisor',
              url: `https://www.ameripriseadvisors.com/?lastAmpAuthState=unrecognized=${userStatus}`
            }}
            secondaryButton={{
              name: 'Log In',
              url: 'https://www.ameriprise.com/client-login'
            }}
            menu={{
              searchPanel: {
                open: false,
                searchBox: {
                  label: 'Search Ameriprise.com',
                  searchUrl: searchUrl,
                  searchAutocompleteUrl: searchAutocompleteUrl
                },
                mostVisitedLinks: {
                  heading: 'Recommended Links',
                  menuItems: [
                    {
                      url: 'https://www.ameriprise.com/financial-planning',
                      name: 'What We Do',
                      icon: 'collaboration'
                    },
                    {
                      url: 'https://www.ameriprise.com/financial-goals-priorities',
                      name: 'Resource Center',
                      icon: 'calculator'
                    },
                    {
                      url: 'https://www.ameriprise.com/financial-goals-priorities/retirement',
                      name: 'Retirement',
                      icon: 'retirement'
                    }
                  ],
                  isEnabled: true,
                  testId: 'most-visited-links'
                }
              },
              menuItems: menuItems
            }}
          />
        </div>
      </div>
    )
  }
}
